import { Diagnosis, PatientInContextPayloadExtended } from '..';
import { Provider } from '../provider';
import { PatientContactInfo } from './patient-contact-info';
import { PatientDemographics } from './patient-demographics';
import { PatientInsurance } from './patientInsurance';

export enum ReleaseOfInformation {
  Y = 'Yes allow to release',
  M = 'modified release (partial release)',
  N = 'no release',
  ANSI = 'ansi 5010',
  UNKNOWN = 'option not mapped',
}

export interface Patient {
  patientId: string;
  mrn?: string;
  demographics: PatientDemographics;
  insurance?: PatientInsurance;
  contact_info?: PatientContactInfo;
  pcp?: Provider;
  problemList?: Diagnosis[];
  vimPatientId?: string;
  releaseOfInformation?: ReleaseOfInformation;
  selfPay?: boolean;
  patientSessionId?: string;
}

export type EnrichedPatient = Patient & PatientInContextPayloadExtended;

export type EnrichedPatientWithoutPII = Pick<
  EnrichedPatient,
  'pcp' | 'problemList' | 'vimPatientId'
> & {
  insurance?: Pick<PatientInsurance, 'ehrInsurance' | 'groupId' | 'insurer' | 'payerId'>;
  demographics?: {
    address?: Pick<Required<PatientDemographics>['address'], 'state'>;
  };
};

export type EnrichedPatientPII = Pick<
  EnrichedPatient,
  'patientId' | 'mrn' | 'demographics' | 'contact_info'
> & {
  insurance?: Pick<PatientInsurance, 'externalMrn' | 'memberId'>;
};
