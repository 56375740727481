import { FetchResult, gql } from '@apollo/client';
import { CdeGqlClientManager } from './gqlClient';
import { CdeJobType, EncryptedPatient, JobDetails } from '../../types';
import { encryption } from '../../components/e2e-encryption';
import { featureFlagsClient } from '../../services';

export interface TakeJobInput {
  publicKey?: string;
}

export interface TakeJobResponse {
  jobUUID: string;
  type: CdeJobType;
  fromDate: string;
  untilDate: string;
  requester: string;
  batchOperationId: number;
  encryptedPatient: EncryptedPatient;
  encounterId?: string;
}

const TAKE_JOB_MUTATION = (shouldFetchEncounterIdFF: boolean) => gql`
  mutation takeJob($input: TakeJobInput!) {
    takeJob(input: $input) {
      jobUUID
      type
      fromDate
      untilDate
      requester
      batchOperationId
      ${shouldFetchEncounterIdFF ? 'encounterId' : ''}
      encryptedPatient {
        encryptedPatientDetails
        encryptedMetadata
      }
    }
  }
`;

export async function takeJob(): Promise<JobDetails | undefined> {
  let shouldFetchEncounterIdFF: boolean;
  try {
    shouldFetchEncounterIdFF = await featureFlagsClient.getFlag({
      flagName: 'shouldFetchEncounterId',
      defaultValue: false,
    });
  } catch (error: any) {
    const modifiedError = {
      ...error,
      detailedMessage: 'Failed to getFlag shouldFetchEncounterId',
    };
    throw modifiedError;
  }

  let result: FetchResult<{ takeJob: TakeJobResponse }>;
  try {
    const gqlClient = await CdeGqlClientManager.getNoRetriesClient();
    result = await gqlClient.mutate<{ takeJob: TakeJobResponse }, { input: TakeJobInput }>({
      mutation: TAKE_JOB_MUTATION(shouldFetchEncounterIdFF),
      variables: { input: { publicKey: encryption.getPublicKey() } },
      fetchPolicy: 'no-cache',
    });
  } catch (error: any) {
    const modifiedError = { ...error, detailedMessage: 'Failed to gqlClient.TAKE_JOB_MUTATION' };
    throw modifiedError;
  }

  if (!result.data?.takeJob) return;

  const {
    encryptedPatient: { encryptedMetadata, encryptedPatientDetails },
    ...rest
  } = result.data.takeJob;
  const decryptedStringifiedPatient = await encryption.decrypt({
    encryptedMessage: encryptedPatientDetails,
    encryptedMetadata,
  });

  return {
    ...rest,
    patient: JSON.parse(decryptedStringifiedPatient),
  };
}
