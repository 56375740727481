import { ActionResult } from '@getvim/platform-types';
import {
  ActionName,
  AffectedEntityType,
  AuditFileMetadataWritingToS3,
  AuditFilesMetadataCreation,
  AuditGetPatientAction,
  AuditGetPatientEncountersActionProps,
  FileExtension,
} from '../../types';
import { postAuditLog } from '../../api/bff';
import { jobManager } from '../../state';

export const auditFileMetadataWritingToS3 = async ({
  file,
  success,
}: AuditFileMetadataWritingToS3) => {
  const { requester, vimPatientId } = jobManager.getAll();
  const { name, extension } = file;

  const fileName = `${name}.${extension}`;
  const affectedFieldName = `Sending the patient data to Vim. ${fileName}`;
  const actionResult = success ? ActionResult.SUCCESS : ActionResult.FAILED;

  return await postAuditLog({
    actionName: ActionName.CDEDisclosureOfInformation,
    actionResult,
    requester,
    affectedEntity: {
      type: AffectedEntityType.Patient,
      affectedFields: [{ name: affectedFieldName }],
      id: { technicalId: vimPatientId },
    },
  });
};

export const auditGetPatientEncounterMetadata = async (success: boolean) => {
  const { requester, vimPatientId } = jobManager.getAll();

  return await postAuditLog({
    actionName: ActionName.CDEGetPatientEncounterMetadataDetails,
    actionResult: success ? ActionResult.SUCCESS : ActionResult.FAILED,
    requester,
    affectedEntity: {
      type: AffectedEntityType.Patient,
      affectedFields: [
        { name: 'Get the patient encounter metadata to share encounter identifiers' },
      ],
      id: { technicalId: vimPatientId },
    },
  });
};

export const auditGetPatientEncounterDetails = async (success: boolean) => {
  const { requester, vimPatientId } = jobManager.getAll();

  return await postAuditLog({
    actionName: ActionName.CDEGetPatientEncounterDetails,
    actionResult: success ? ActionResult.SUCCESS : ActionResult.FAILED,
    requester,
    affectedEntity: {
      type: AffectedEntityType.Patient,
      affectedFields: [{ name: 'Get the patient encounter details to share encounter notes' }],
      id: { technicalId: vimPatientId },
    },
  });
};

export const auditFilesMetadataCreation = async ({ files }: AuditFilesMetadataCreation) => {
  const { requester, vimPatientId } = jobManager.getAll();

  await Promise.all(
    files.map(async ({ extension }) => {
      const actionName =
        extension === FileExtension.JSON
          ? ActionName.CDEGetPatientEncounterMetadataDetails
          : ActionName.CDEGetPatientEncounterDetails;

      const affectedFieldName =
        extension === FileExtension.JSON
          ? 'Get the patient encounter metadata to share encounter identifiers'
          : 'Get the patient encounter details to share encounter notes';

      return await postAuditLog({
        actionName,
        actionResult: ActionResult.SUCCESS,
        requester,
        affectedEntity: {
          type: AffectedEntityType.Patient,
          affectedFields: [{ name: affectedFieldName }],
          id: { technicalId: vimPatientId },
        },
      });
    }),
  );
};

export const auditGetPatientEncountersAction = async ({
  success,
}: AuditGetPatientEncountersActionProps) => {
  const { requester, vimPatientId, fromDate, untilDate } = jobManager.getAll();

  await postAuditLog({
    actionName: ActionName.CDEPatientEncounterList,
    actionResult: success ? ActionResult.SUCCESS : ActionResult.FAILED,
    requester,
    affectedEntity: {
      type: AffectedEntityType.Patient,
      id: { technicalId: vimPatientId },
      affectedFields: [
        {
          name: `Get the patient's encounter list and metadata from ${fromDate} to ${untilDate}`,
        },
      ],
    },
  });
};

export const auditGetPatientAction = async ({ success }: AuditGetPatientAction) => {
  const { requester, vimPatientId } = jobManager.getAll();

  await postAuditLog({
    actionName: ActionName.CDEGetPatient,
    requester,
    actionResult: success ? ActionResult.SUCCESS : ActionResult.FAILED,
    affectedEntity: {
      type: AffectedEntityType.Patient,
      affectedFields: [{ name: 'Get the patient details for patient, CDE prerequisite' }],
      id: { technicalId: vimPatientId },
    },
  });
};
